<template>
  <AuthPage
    context="sign_up"
    data-id="auth-page-signup"
    :title="title"
    :form-button="'Sign up'"
    :done="done"
    :show-federated-login="true"
    :loading="loading"
    :subtitle="description"
    :terms="true"
    @submit="submit"
  >
    <template #done>
      <DoneImage
        title="One more step..."
        done-message="Open your email and click the verification link."
        :done="true"
        done-image="/open-email.svg"
      />
    </template>
    <template #notes>
      <AuthNotesCard>
        Please check your spam folder and allow emails from
        <strong>getmailslurp.com</strong>
      </AuthNotesCard>
    </template>
    <template #subtitle>
      Already registered?
      <AuthLink
        :tabindex="-1"
        title="Login"
        to="/login"
        data-id="auth-link-login"
      />
    </template>
    <template #form>
      <AuthFormInput
        id="email"
        v-model="username"
        label="Email address"
        type="email"
        autocomplete="username"
        placeholder="you@company.com"
        required
        data-id="email"
      >
        <template #label>
          <AuthLabelLink
            :text="!usePassword ? 'Use password' : 'Use email link'"
            tabindex="-1"
            data-id="password-toggle-button"
            :data-enabled="usePassword ? '1' : '0'"
            @click="usePassword = !usePassword"
          />
        </template>
      </AuthFormInput>
      <AuthFormInput
        id="password"
        v-if="usePassword"
        v-model="password"
        minlength="8"
        label="Password"
        type="password"
        autocomplete="current-password"
        required
        data-id="password"
      />
    </template>
  </AuthPage>
</template>
<script setup lang="ts">
import { useRoute, onMounted, ref, navigateTo } from '#imports';
import { useStore3 } from '../../../store/main';
import { ErrorCodesDtoAppErrorCodesEnum } from '@mailslurp/admin-sdk';
import type { ApiError } from '../../../lib/services/helpers';
import AuthPage from '../AuthPage.vue';
import AuthLink from '../AuthLink.vue';
import AuthFormInput from '../AuthFormInput.vue';
import { storeToRefs } from 'pinia';
import DoneImage from '../../card/DoneImage.vue';
import type { ErrorPayload } from '../../../store/types';
import { DEFAULT_SIGNUP_WITH_PASSWORD } from '../../../lib/services/configFlags';
import AuthNotesCard from '../AuthNotesCard.vue';
import AuthLabelLink from './AuthLabelLink.vue';
import { useNuxtApp } from '#imports';
import { getLogger } from '../../../lib/services/getLogger';
const { $captureEvent: captureEvent } = useNuxtApp();

const store3 = useStore3();
const route = useRoute();

const log = getLogger('sign-up');
const username = ref('');
const usePassword = ref(DEFAULT_SIGNUP_WITH_PASSWORD);
const password = ref('');
const done = ref<boolean>(false);
const loading = ref(false);
const selectedPlan = ref<string | undefined>(undefined);
const hideSso = ref<boolean>(false);
const preventMultiple = ref(true);
withDefaults(defineProps<{ title?: string; description?: string }>(), {
  title: 'Sign Up',
  description: '',
});

onMounted(async () => {
  // get query params
  const query = route.query;
  const {
    emailAddress = null,
    noFlash = null,
    useEmail: _useEmail = false,
    submit: _submit = false,
    hideSso: _hideSso = false,
    allowMultiple: _allowMultiple = false,
  } = query;
  log('Sign up params ' + query);
  // if requested plan set it as the current plan
  if (noFlash) {
    store3.setFlash(null);
  }
  if (_useEmail) {
    usePassword.value = false;
  }
  if (_hideSso) {
    hideSso.value = true;
  }
  if (emailAddress) {
    log('Setting username from query param ' + emailAddress);
    username.value = emailAddress as string;
  }
  if (_allowMultiple) {
    preventMultiple.value = false;
  }
  if (_submit && !usePassword.value) {
    await submit();
  }
});
const { requestedPlan } = storeToRefs(store3);
const submit = async () => {
  loading.value = true;
  try {
    log('Set requested plan');
    requestedPlan.value = selectedPlan.value!;
    log('Dispatch sign in');
    if (usePassword.value) {
      log('Sign in with password');
      const user = await store3.signUp(
        {
          password: password.value,
          emailAddress: username.value,
        },
        requestedPlan.value,
      );
      await store3.getUserForToken(user.apiKey! as string);
      log(`Returned user ${user?.id}`);
      await store3.setUserApiKey(user);
      captureEvent('begin_sign_up', {
        method: 'password',
      });
      await navigateTo('/dashboard/?signUp=1');
    } else {
      log('Sign in with email address ' + username.value);
      captureEvent('begin_sign_up', {
        method: 'email',
      });
      await store3.signIn({
        emailAddress: username.value,
        requestedPlanId: selectedPlan.value,
        preventMultipleUsers: preventMultiple.value,
        isSignUp: true,
      });
      done.value = true;
    }
  } catch (e: unknown) {
    const error: ErrorPayload = {
      error: e,
      text: e as string | undefined,
    };
    captureEvent('begin_sign_up_error', {
      method: usePassword.value ? 'password' : 'email',
    });
    console.error('Sign up error', e);
    const apiError = e as ApiError;
    if (
      apiError?.apiResult?.errorCode ===
      ErrorCodesDtoAppErrorCodesEnum.W_401_MULTIPLE_ACCOUNTS
    ) {
      await navigateTo('/accounts?emailAddress=' + username.value);
    }
    store3.setError(error);
  } finally {
    loading.value = false;
  }
};
</script>
